import App, { Container } from 'next/app';
import React, { createRef } from 'react';
import withReduxStore from 'utils/withReduxStore';
import { Provider } from 'react-redux';
import { Router } from 'Router';
import * as Sentry from '@sentry/react';
import config from 'config';

import { loadClipboard } from 'containers/Clipboard/store';
import Loader from 'components/Loader';
import { pageView } from 'utils/googleAnalytics/gtag';

import { Cookies } from 'react-cookie-consent';

const isProduction = process.env.NODE_ENV === 'production';
const SEARCH_PAGES = ['/wyszukiwarka', '/wyszukiwarka-inwestycji'];

if (isProduction) {
  Sentry.init({
    dsn: 'https://69df8232f860448abbfd1f05f0dbd0da@sentry.fingoweb.com/16',
    release: config.version,
  });
}

class MyApp extends App {
  constructor(props) {
    super(props);

    this.state = {
      overlay: false,
    };

    this.isFirstLoad = createRef();
    this.isFirstLoad.current = true;
  }

  componentDidMount() {
    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeComplete);
    loadClipboard()(this.props.reduxStore.dispatch, this.props.reduxStore.getState);
    this.isFirstLoad.current = false;
  }

  componentDidCatch(error, errorInfo) {
    if (isProduction) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });

      super.componentDidCatch(error, errorInfo);
    }
  }

  routeChangeStart = () => {
    this.setState({
      overlay: true,
    });
  }

  routeChangeComplete = (url) => {
    if (Cookies.get(config.statisticCookies) === 'true') { pageView(url); }
    this.setState({
      overlay: false,
    });
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;

    const { overlay } = this.state;
    const isSearchPage = SEARCH_PAGES.includes(Router.router ? Router.router.pathname : '');

    return (
      <Container>
        <Provider store={reduxStore}>
          <React.Fragment>
            <Loader
              overlay={overlay}
              minTime={isSearchPage
                ? config.minLoaderTime : 0}
            />
            <Component {...pageProps} />
          </React.Fragment>
        </Provider>
      </Container>
    );
  }
}

export default withReduxStore(MyApp);
