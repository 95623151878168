// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page_view',
    page: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action, category, label, value,
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: label,
    value,
    action,
    category,
  });
};
