function trackYouleadFavourite(favouriteItems) {
  // eslint-disable-next-line no-multi-assign
  const ylData = window.ylData = window.ylData || [];

  const products = favouriteItems.map((favouriteItem) => ({
    productId: favouriteItem,
  }));


  ylData.push({ cart: { products } });

  return ylData;
}

export default trackYouleadFavourite;
