import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  data: [],
  svgData: null,
};

export const actionTypes = {
  LOAD_DATA_INIT: 'INVESTMENT_BUILDING_SEARCH/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'INVESTMENT_BUILDING_SEARCH/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'INVESTMENT_BUILDING_SEARCH/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const newState = { ...initialState };

      newState.data = GraphQLTransforms.getNode(action.data[0]);
      newState.svgData = action.data[1];

      return newState;
    }
    default:
      return state;
  }
};

export const loadData = (id) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      query getBuildings($id: String) {
        nodeQuery(filter: {conditions: [
          {field: "type", value: ["building"]},
          {field: "status", value: ["1"]}
          {field: "field_building_investment.entity.nid", value: [$id]}
        ]}) {
          entities {
            ... on NodeBuilding {
              id: fieldBuildingNumber,
              short: fieldBuildingNumber,
              title,
              systemId: nid,          
              slug: fieldBuildingSlug,    
            }
          }
        }
      }
    `,
    variables: { id },
  });

  requests.push({
    method: 'GET',
    url: `svgConverter/investment/${id}`,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
