import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  sliderItems: [],
  statisticsItems: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'STATIC/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'STATIC/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'STATIC/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;
      return {
        ...state,
        data: {
          ...data,
          image: GraphQLTransforms.imageStyle(data.image),
          content: GraphQLTransforms.value(data.content),
        },
      };
    }
    default:
      return state;
  }
};

export const loadData = (id) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      query getStaticPage($id: String!) {
        nodeById(id: $id) {
          ... on NodeStatic {
            id: nid,
            title,
            showMap: fieldShowMap,
            image: fieldStaticBreadcrumbImg {
              derivative(style: ORIGINAL) {
                url,
              }
            },
            content: body {
              value
            }
          }
        }
      }
    `,
    variables: { id },
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
