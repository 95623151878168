import ApiManager from 'utils/ApiManager';

export const initialState = {
  svgData: null,
};

export const actionTypes = {
  LOAD_DATA_INIT: 'INVESTMENT_FLOOR_SEARCH/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'INVESTMENT_FLOOR_SEARCH/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'INVESTMENT_FLOOR_SEARCH/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const newState = { ...initialState };

      newState.svgData = action.data[0];

      return newState;
    }
    default:
      return state;
  }
};

export const loadData = (id) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    method: 'GET',
    url: `svgConverter/building/${id}`,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
