import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  data: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'NEWS_SINGLE/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'NEWS_SINGLE/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'NEWS_SINGLE/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;

      return {
        ...state,
        data: {
          title: data.title,
          content: data.content ? data.content.value : '',
          timestamp: data.timestamp * 1000,
          mainPhoto: GraphQLTransforms.imageStyle(data.mainPhoto),
          galleryThumbnails: data.galleryThumbnails.map(GraphQLTransforms.imageStyle),
          galleryModal: data.galleryModal.map(GraphQLTransforms.imageStyle),
          gallery: data.gallery.map(GraphQLTransforms.imageStyle),
        },
      };
    }
    default:
      return state;
  }
};

export const loadData = (newsSlug) => (dispatch, getStore) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const store = getStore();
  const newsId = (store.Shared.mappedNewsSlugs[newsSlug] || newsSlug).toString();

  const requests = {
    type: 'graphql',
    query: `
      query getNews($id: String!) {
        nodeById(id: $id) {
          ... on NodeNews {
            id: nid,
            slug: fieldNewsSlug
            title,
            timestamp: created,
            content: fieldNewsDescription {
              value,
            },
            mainPhoto: fieldNewsMainPhoto {
              derivative(style: NEWSGALLERY) {
                url,
              }
            }
            gallery: fieldNewsGallery {
              derivative(style: NEWSGALLERY) {
                url,
              }
            }
            galleryThumbnails: fieldNewsGallery {
              derivative(style: NEWSGALLERYTHUMB) {
                url,
              }
            }
            galleryModal: fieldNewsGallery {
              derivative(style: NEWSGALLERYMODAL) {
                url,
              }
            }
          }
        }
      }
    `,
    variables: { id: newsId },
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
