import { combineReducers } from 'redux';

import { reducer as HomePage } from 'containers/Home/store';
import { reducer as ClipboardPage } from 'containers/Clipboard/store';
import { reducer as CollaborationPage } from 'containers/Collaboration/store';
import { reducer as BuyingAssetsPage } from 'containers/BuyingAssets/store';
import { reducer as ContactPage } from 'containers/Contact/store';
import { reducer as InvestmentDescriptionPage } from 'containers/InvestmentDescription/store';
import { reducer as InvestmentBuildingSearchPage } from 'containers/InvestmentBuildingSearch/store';
import { reducer as InvestmentFloorSearchPage } from 'containers/InvestmentFloorSearch/store';
import { reducer as InvestmentLocalSearchPage } from 'containers/InvestmentLocalSearch/store';
import { reducer as InvestmentTableSearchPage } from 'containers/InvestmentTableSearch/store';
import { reducer as InvestmentListPage } from 'containers/InvestmentList/store';
import { reducer as LocalPage } from 'containers/Local/store';
import { reducer as ConstructionJournalListPage } from 'containers/ConstructionJournalList/store';
import { reducer as CustomerOpinionListPage } from 'containers/CustomerOpinionList/store';
import { reducer as MediaAboutUsListPage } from 'containers/MediaAboutUsList/store';
import { reducer as PromotionsListPage } from 'containers/PromotionsList/store';
import { reducer as PromotionLocalsListPage } from 'containers/PromotionLocalsList/store';
import { reducer as BlogPage } from 'containers/Blog/store';
import { reducer as BlogPostPage } from 'containers/BlogPost/store';
import { reducer as NewsListPage } from 'containers/NewsList/store';
import { reducer as NewsSinglePage } from 'containers/NewsSingle/store';
import { reducer as PoIPage } from 'containers/PoI/store';
import { reducer as PriceListPage } from 'containers/PriceList/store';
import { reducer as SearchPage } from 'containers/Search/store';
import { reducer as SearchInvestmentPage } from 'containers/SearchInvestment/store';
import { reducer as StaticPage } from 'containers/Static/store';
import { reducer as LegalInformation } from 'containers/LegalInformation/store';
import { reducer as Shared } from 'containers/sharedStore';

const appReducer = combineReducers({
  HomePage,
  ClipboardPage,
  ContactPage,
  CollaborationPage,
  BuyingAssetsPage,
  InvestmentDescriptionPage,
  InvestmentBuildingSearchPage,
  InvestmentFloorSearchPage,
  InvestmentLocalSearchPage,
  InvestmentTableSearchPage,
  InvestmentListPage,
  LocalPage,
  LegalInformation,
  ConstructionJournalListPage,
  CustomerOpinionListPage,
  MediaAboutUsListPage,
  PromotionsListPage,
  PromotionLocalsListPage,
  BlogPage,
  BlogPostPage,
  NewsListPage,
  NewsSinglePage,
  PoIPage,
  PriceListPage,
  SearchPage,
  SearchInvestmentPage,
  StaticPage,
  Shared,
});

export const rootReducer = (state, action) => appReducer(state, action);
