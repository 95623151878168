import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';
import { RequestError } from 'utils/errors/RequestError';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

import config from 'config';

export const initialState = {
  data: {
    gallery: {},
    surroundings: {},
  },
  svgData: null,
  svgDataError: false,
  position: {
    [config.locationIds.details]: null,
    [config.locationIds.gallery]: null,
    [config.locationIds.search]: null,
    [config.locationIds.localization]: null,
  },
  svgType: 'investment',
  svgExists: true,
  selectedLocalType: config.aggregatedLocalTypes.flat,
  selectedViewType: config.descriptionOptionIds.view3D,
  isInvestmentListExpanded: false,
  filters: {
    floorMaxSelected: undefined,
    floorMinSelected: undefined,
    priceMaxSelected: undefined,
    priceMinSelected: undefined,
    priceM2MaxSelected: undefined,
    priceM2MinSelected: undefined,
    roomsMaxSelected: undefined,
    roomsMinSelected: undefined,
    surfaceMaxSelected: undefined,
    surfaceMinSelected: undefined,
  },
  isReturn: false,
};

export const actionTypes = {
  RESET_SVG: 'INVESTMENT_DESCRIPTION/RESET_STATE',
  LOAD_DATA_INIT: 'INVESTMENT_DESCRIPTION/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'INVESTMENT_DESCRIPTION/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'INVESTMENT_DESCRIPTION/LOAD_DATA_ERROR',
  LOAD_SVG_SUCCESS: 'INVESTMENT_DESCRIPTION/LOAD_SVG_SUCCESS',
  LOAD_SVG_ERROR: 'INVESTMENT_DESCRIPTION/LOAD_SVG_ERROR',
  SET_SVG_TYPE: 'INVESTMENT_DESCRIPTION/SET_SVG_TYPE',
  SET_SVG_EXISTS: 'INVESTMENT_DESCRIPTION/SET_SVG_EXISTS',
  CHANGE_POSITION: 'INVESTMENT_DESCRIPTION/CHANGE_POSITION',
  CHANGE_LOCAL_TYPE: 'INVESTMENT_DESCRIPTION/CHANGE_LOCAL_TYPE',
  CHANGE_FILTERS: 'INVESTMENT_DESCRIPTION/CHANGE_FILTERS',
  EXPAND_INVESTMENTS_LIST: 'INVESTMENT_DESCRIPTION/EXPAND_INVESTMENTS_LIST',
  CHANGE_VIEW_TYPE: 'INVESTMENT_DESCRIPTION/CHANGE_VIEW_TYPE',
  CHANGE_IS_RETURN: 'INVESTMENT_DESCRIPTION/CHANGE_IS_RETURN',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPAND_INVESTMENTS_LIST:
      return {
        ...state,
        isInvestmentListExpanded: true,
      };
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;

      return {
        ...state,
        data: {
          journal: GraphQLTransforms.getNode(action.data[2]).map((el) => ({
            date: GraphQLTransforms.timestamp(_get(el, 'timestamp.value', 0), true),
            photos: el.images.map(GraphQLTransforms.imageStyle),
            thumbnails: el.thumbnails.map(GraphQLTransforms.imageStyle),
          })).sort((firstEl, secondEl) => firstEl.date - secondEl.date),
          buildings: GraphQLTransforms.getNode(action.data[1]),
          investment: data.investment,
          content: data.content.value,
          gallery: {
            photos: data.gallery.map(GraphQLTransforms.imageStyle),
            thumbnails: data.thumbnailGallery.map(GraphQLTransforms.imageStyle),
          },
          surroundings: {
            photos: data.surroundings.map(({ image }) => (image.url)),
            thumbnails: data.surroundings.map(({ thumbnail }) => (thumbnail.url)),
          },
          folderUrl: GraphQLTransforms.image(data.folderUrl),
          detailIcons: data.detailIcons.map((detailIcon) => ({
            image: GraphQLTransforms.url(detailIcon.entity.image),
            description: GraphQLTransforms.value(detailIcon.entity.description),
          })),
          dronePictures: data.dronePictures,
          alternativePositionMock: data.alternativePositionMock,
        },
      };
    }
    case actionTypes.LOAD_SVG_SUCCESS: {
      const svgEntities = action.data[0].childs[0].childs
        .filter((el) => el.name === 'path' || el.attrs['data-floor'])
        .map((el) => ({
          id: el.attrs.id,
          nr: el.attrs.id,
          title: el.attrs.id,
        }));

      return {
        ...state,
        svgData: action.data[0],
        svgDataError: false,
        svgExists: true,
        svgEntities,
      };
    }
    case actionTypes.LOAD_SVG_ERROR: {
      return {
        ...state,
        svgDataError: true,
        svgExists: false,
      };
    }
    case actionTypes.RESET_SVG:
      return {
        ...state,
        svgData: null,
        svgEntities: null,
        svgType: null,
        svgBuildingId: null,
        svgFloorId: null,
      };
    case actionTypes.SET_SVG_TYPE: {
      return ({
        ...state,
        svgType: action.name,
        svgBuildingId: action.buildingId,
        svgFloorId: action.floorId,
      });
    }

    case actionTypes.SET_SVG_EXISTS: {
      return {
        ...state,
        svgExists: action.svgExists,
      };
    }

    case actionTypes.CHANGE_POSITION: {
      const data = action.position;

      return {
        ...state,
        position: {
          ...state.position,
          [data.id]: data.position,
        },
      };
    }

    case actionTypes.CHANGE_LOCAL_TYPE: {
      return {
        ...state,
        selectedLocalType: action.localType,
      };
    }

    case actionTypes.CHANGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    }

    case actionTypes.CHANGE_VIEW_TYPE: {
      return {
        ...state,
        selectedViewType: action.viewType,
      };
    }

    case actionTypes.CHANGE_IS_RETURN: {
      return {
        ...state,
        isReturn: action.isReturn,
      };
    }

    default:
      return state;
  }
};

export const changeIsReturn = (isReturn) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_IS_RETURN, isReturn });
};

export const changeFilters = (filters) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_FILTERS, filters });
};

export const changeSvgType = (name, buildingId, floorId) => ({
  type: actionTypes.SET_SVG_TYPE,
  name,
  buildingId,
  floorId,
});

const changePosition = (position) => ({
  type: actionTypes.CHANGE_POSITION,
  position,
});

export const changeLocalType = (localType) => ({
  type: actionTypes.CHANGE_LOCAL_TYPE,
  localType,
});

export const changeViewType = (viewType) => ({
  type: actionTypes.CHANGE_VIEW_TYPE,
  viewType,
});

export const setSVGExists = (svgExists) => ({
  type: actionTypes.SET_SVG_EXISTS,
  svgExists,
});

export const resetSVGData = () => ({
  type: actionTypes.RESET_SVG,
});

export const expandInvestmentsList = () => ({
  type: actionTypes.EXPAND_INVESTMENTS_LIST,
});

export const onPositionChange = (position) => (dispatch) => {
  dispatch(changePosition(position));
};

export const loadData = (investment) => (dispatch, getStore) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const store = getStore();
  const investmentSlug = store.Shared.mappedInvestmentsSlugs[investment].toString() || investment;

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      query getInvestment($id: String!) {
        nodeById(id: $id) {
          ... on NodeInvestment {
            investment: nid,
            content: fieldInvestmentDesc {
              value
            },
            surroundings: fieldSurroundings {
              image: derivative(style: ORIGINAL) {
                url
              }
              thumbnail: derivative(style: INVESTMENTS) {
                url
              }
            }
            gallery: fieldInvestmentGallery {
              derivative(style: ORIGINAL) {
                url
              }
            },
            thumbnailGallery: fieldInvestmentGallery {
              derivative(style: INVESTMENTS) {
                url
              }
            },
            folderUrl: fieldInvestmentCatalog {
              entity {
                url
              }
            }
            detailIcons: fieldDetailIcons {
              entity {
                ... on ParagraphImageWithDescription {
                  image: fieldImage {
                    url,
                  },
                  description: fieldDescription {
                    value
                  }
                }
              }
            }
            dronePictures: fieldInvestmentDronePictures
            alternativePositionMock: fieldInvestmentAlterPosMock
          }
        }
      }
    `,
    variables: { id: investmentSlug },
  });

  requests.push({
    type: 'graphql',
    query: `
      query getBuildings($id: String) {
        nodeQuery(filter: {conditions: [
          {field: "type", value: ["building"]},
          {field: "status", value: ["1"]}
          {field: "field_building_investment.entity.nid", value: [$id]}
        ]}) {
          entities {
            ... on NodeBuilding {
              id: fieldBuildingNumber,
              short: fieldBuildingNumber,
              title,
              systemId: nid,
              slug: fieldBuildingSlug,  
            }
          }
        }
      }
    `,
    variables: { id: investmentSlug },
  });

  requests.push({
    type: 'graphql',
    query: `query getJournal($id: String) {
      nodeQuery(filter: {conditions: [
        {field: "type", value: ["construction_journal"]},
        {field: "status", value: ["1"]},
        {field: "field_construction_investment.entity.nid", value: [$id]},
      ]}) {
        entities {
          ... on NodeConstructionJournal {
            id: nid,
            title,
            timestamp: fieldConstructionDate {
              value
            },
            images: fieldConstructionGallery {
              derivative(style: ORIGINAL) {
                url,
              }
            },
            thumbnails: fieldConstructionGallery {
              derivative(style: CONSTRUCTIONJOURNALGALLERY) {
                url,
              }
            }
            body {
              value
            },
          }
        }
      }
    }`,
    variables: { id: investmentSlug },
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};

export const loadSVGData = (svgId) => async (dispatch, getStore) => {
  const actions = {
    onInit: actionTypes.LOAD_SVG_INIT,
    onSuccess: actionTypes.LOAD_SVG_SUCCESS,
    onError: actionTypes.LOAD_SVG_ERROR,
  };

  const sharedStore = getStore().Shared;
  const store = getStore().InvestmentDescriptionPage;
  const {
    data,
    svgType,
    svgBuildingId,
    svgFloorId,
  } = store;

  if ((data && svgType && svgBuildingId && svgFloorId) || (data && svgType === 'investment')) {
    dispatch(setSVGExists(true));
  } else {
    dispatch(setSVGExists(false));
  }
  const { buildings, investment } = data;

  let type;
  let id;
  let buildingId = svgBuildingId;
  let floorId = svgFloorId;

  const isInvestmentWithoutBuildingSelect = _includes(
    config.investmentsWithoutBuildingSelect,
    investment,
  );
  if (svgType === 'investment' && (
    (buildings && buildings.length === 1) || isInvestmentWithoutBuildingSelect
  )) {
    type = 'building';
    id = buildings[0].slug;
  } else if (svgType === 'investment' && !_isNil(svgId)) {
    type = 'building';
    id = svgId;
  } else if ((svgType === 'building' || svgType === 'floor') && !_isNil(svgId)) {
    type = 'floor';
    id = svgBuildingId;
    floorId = svgId;
  } else if (buildings) {
    type = 'investment';
    id = investment;
  }

  if (type === 'building') {
    buildingId = id;
  }

  const converterBuildingId = sharedStore.mappedBuildingsSlugs[id].toString() || id;

  const requests = [{
    method: 'GET',
    url: `svgConverter/${type}/${converterBuildingId}?attachWindRose`,
  }];

  try {
    await ApiManager.request({
      dispatch,
      actions,
      requests,
    });
    return dispatch(changeSvgType(type, buildingId, floorId));
  } catch (error) {
    if (!(error instanceof RequestError)) throw error;
    return null;
  }
};
