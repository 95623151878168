import Cookies from 'js-cookie';
import pull from 'lodash/pull';


import config from 'config';
import trackYouleadFavourite from 'utils/YouLead/TrackYouleadFavourite';

export const initialState = {
  items: [],
  loaded: false,
};

export const actionTypes = {
  TOGGLE_CLIPBOARD_ITEM: 'CLIPBOARD/TOGGLE_CLIPBOARD_ITEM',
  LOAD_CLIPBOARD: 'CLIPBOARD/LOAD_CLIPBOARD',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_CLIPBOARD_ITEM: {
      let items = [...state.items];

      if (items.indexOf(action.id) > -1) {
        items = pull(items, action.id);
      } else {
        items.push(action.id);
      }

      trackYouleadFavourite(items);

      Cookies.set('clipboard', items, { expires: config.cookie.daysToExpire });
      return {
        ...state,
        items,
      };
    }
    case actionTypes.LOAD_CLIPBOARD: {
      return {
        ...state,
        items: action.data || [],
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const toggleClipboardItem = (id) => ({
  type: actionTypes.TOGGLE_CLIPBOARD_ITEM,
  id,
});

export const loadClipboard = (val) => (dispatch, getState) => {
  const state = getState();

  if (!state.ClipboardPage.loaded) {
    dispatch({
      type: actionTypes.LOAD_CLIPBOARD,
      data: val || Cookies.getJSON('clipboard'),
    });
  }
};
export const loadCookieData = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_CLIPBOARD,
    data: Cookies.getJSON('clipboard'),
  });
};
