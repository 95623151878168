import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  buildings: [],
  otherInvestments: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'INVESTMENT_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'INVESTMENT_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'INVESTMENT_LIST/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0];
      return {
        ...state,
        buildings: GraphQLTransforms.getNode(data, 'investments').map((building) => ({
          ...building,
          date: GraphQLTransforms.timestamp(building.date && building.date.value),
          picture: building.picture.map(GraphQLTransforms.imageStyle),
          pictureThumbnail: building.pictureThumbnail.map(GraphQLTransforms.imageStyle),
          gallery: building.gallery.map(GraphQLTransforms.imageStyle),
          galleryThumbnails: building.galleryThumbnails.map(GraphQLTransforms.imageStyle),
          investment: building.investment.entity,
          investments: building,
        })),
        otherInvestments: GraphQLTransforms.getNode(data, 'other'),
      };
    }
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      {
        investments: nodeQuery(
          limit: 1000, 
          filter: {
            conditions: [
              {field: "type", value: ["building"]},
              {field: "status", value: ["1"]}
            ]
          }
        ) {
          entities {
            ... on NodeBuilding {
              id: nid,
              title,
              slug: fieldBuildingSlug,  
              investment: fieldBuildingInvestment {
                entity {
                  id: nid
                  title
                }
              },
              date: fieldBuildingFinishDate {
                value
              },
              gallery: fieldGallery {
                derivative(style: ORIGINAL) {
                  url
                } 
              }
              galleryThumbnails: fieldGallery {
                derivative(style: INVESTMENTS) {
                  url
                } 
              }
              
              picture: fieldBuildingPicture {
                derivative(style: ORIGINAL) {
                  url
                } 
              }
              
              pictureThumbnail: fieldBuildingPicture {
                derivative(style: INVESTMENTS) {
                  url
                } 
              }
            }
          }
        }
        other: nodeQuery(filter: {conditions: [{field: "type", value: ["other_investments"]}]}) {
          entities {
            ... on NodeOtherInvestments {
              id: nid,
              address: fieldOtherInvestmentAddress,
              type: fieldOtherInvestmentType, 
            }
          }
        }
      }
    `,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
