import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from 'containers/reducers';

const initialValues = {};

const initStore = (initialStateNext = initialValues) => {
  const store = createStore(
    rootReducer,
    initialStateNext,
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../containers/reducers', () => {
      const nextReducers = require('../containers/reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducers);
    });
  }

  return store;
};

export default initStore;
